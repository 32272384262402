import BaseService from './BaseService'

class CallbackSurveyService extends BaseService {
  async questionnaires(params = {}) {
    const res = await this.get('/questionnaires/callback', { key: params.key })
    return res.data
  }
}

export default new CallbackSurveyService()
