import checkCampaign from '../middleware/getCampaign';

export const routes = [
  {
    path: '/questionnaires/callback',
    name: 'questionnaires.callback',
    component: () => import('@views/GifteeCP/Campaign/Containers/Questionnaires')
  },
  {
    path: '/:campaignUrlName/:campaignUrl',
    component: () => import('@views/GifteeCP'),
    meta: {
      middleware: [checkCampaign],
    },
    children: [
      {
        path: '/',
        name: 'campaign.home',
        component: () => import('@views/GifteeCP/Campaign/Containers/Home'),
        meta: {
          middleware: [checkCampaign],
        }
      },
      {
        path: 'sms',
        name: 'campaign.auth.sms',
        component: () => import('@views/GifteeCP/Campaign/Containers/SmsAuth'),
        meta: {
          middleware: [checkCampaign],
        }
      },
      {
        path: 'auth',
        name: 'campaign.auth.serial',
        component: () => import('@views/GifteeCP/Campaign/Containers/SerialAuth'),
        meta: {
          middleware: [checkCampaign],
        }
      },
      {
        path: 'smsserialauth',
        name: 'campaign.auth.smsserial',
        component: () => import('@views/GifteeCP/Campaign/Containers/SmsSerialAuth'),
        meta: {
          middleware: [checkCampaign],
        }
      }
    ]
  },
  {
    path: '/:campaignUrlName/lottery/:lotteryUuid',
    name: 'lottery.game',
    component: () => import('@views/GifteeCP/Campaign/Containers/Lottery')
  },
  {
    path: '/error',
    name: 'campaign.error',
    component: () => import('@views/Errors/Containers/CampaignError'),
    props: true,
  },
  {
    path: '/error-404',
    alias: '*',
    name: 'campaign.error.404',
    component: () => import('@views/Errors/Containers/Error404')
  }
]
