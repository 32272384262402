<template>
  <div id="app" class="app-container">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-vue/dist/bootstrap-vue.min.css";
@import "@assets/scss/styles.scss";
</style>

<script>
export default {
  name: 'App'
}
</script>
