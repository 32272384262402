// HTTP CODE definition
export const HTTP_CODE_NOT_FOUND = 404
export const HTTP_CODE_BAD_REQUEST = 400
export const HTTP_CODE_OK = 200
export const HTTP_CODE_IS_EXPIRED = 100
export const HTTP_CODE_GET_SURVEY_LINK_FAILED = 413
export const HTTP_CODE_CAMPAIGN_URL_NAME_LOTTERY_UUID_WRONG = 414
export const HTTP_CODE_LOTTERY_UUID_NOT_EXIST = 409
export const HTTP_CODE_PHONE_NUMBER_IS_EXIST = 300
export const HTTP_CODE_SERIAL_CODE_NOT_ENOUGH = 301
export const HTTP_PHONE_NUMBER_NOT_IN_WHITE_LIST = 302
export const HTTP_CODE_RATE_LIMIT_EXCEED = 425

export const HTTP_CODE_PHONE_NUMBER_ERRORS = [
    HTTP_CODE_PHONE_NUMBER_IS_EXIST,
    HTTP_CODE_SERIAL_CODE_NOT_ENOUGH,
    HTTP_PHONE_NUMBER_NOT_IN_WHITE_LIST
]
export const HTTP_CODE_SERIAL_CODE_IS_USED_PHONE = 310

// Loading screen
export const DEFAULT_LOADING_FILL = '#F08B71'
export const DEFAULT_LOADING_WIDTH = '60px'
export const DEFAULT_LOADING_HEIGHT = '60px'

// Campaign Infos
export const CAMPAIGN_SERIAL_TYPE = 2 // Serial auth
export const CAMPAIGN_SERIAL_VIA_SMS_TYPE = 99 // Serial code via sms
export const CAMPAIGN_SMS_TYPE = 1 // Sms auth
export const CAMPAIGN_SMS_SERIAL_TYPE = 3 // Sms Serial auth
export const CAMPAIGN_IS_EXPIRED = 1

export const CAMPAIGN_TYPE_HAVE_SERIAL_CODE = [CAMPAIGN_SERIAL_TYPE, CAMPAIGN_SERIAL_VIA_SMS_TYPE]
export const CAMPAIGN_TYPE_HAVE_SMS = [CAMPAIGN_SMS_TYPE, CAMPAIGN_SERIAL_VIA_SMS_TYPE]

// Current step definition
export const IS_NOT_ANIMATION = 0
export const IS_ANIMATION = 1
export const WIN_FLAG = 1
export const LOSE_FLAG = 2
export const DELAY_LOTTERY_GAME = 3000
export const NOT_DELAY_LOTTERY_GAME = 0

// Auth page
export const SERIAL_CODE_DEFAULT = '0'

// App language
export const APP_LANG_DEFAULT = 1 // EN
export const APP_LANG_VN = 2 // VN
export const APP_LANG_DEFAULT_FONT = 'Montserrat'
export const APP_LANG_VN_FONT = 'Helvetica Neu, Helvetic, Arial, sans-serif'

// Background lottery game
export const DEFAULT_BACKGROUND_LOTTERY_GAME = '#FFFFFF'

// Type's button submit
export const DEFAULT_BUTTON_SUBMIT = 'default_btn_submit'

// Campaign flow
export const FLOW_TYPE_REDIRECT = 2;
