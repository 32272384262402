import Vue from 'vue'
import App from './App.vue'

// ====== Route
import router from './router'

// ====== BootstrapVue
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

// ====== Input Mask
import VueMask from 'di-vue-mask'
Vue.use(VueMask)

// ====== Locale
import i18n from '@i18n'

Vue.directive('uppercase', {
  update: function(el) {
    el.value = el.value.toUpperCase()
  }
})

Vue.prototype.$setAppStyles = (objStyle) => {
  Object.entries(objStyle).forEach(([nameVar, value]) =>
    document.documentElement.style.setProperty(nameVar, value)
  )
}

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
