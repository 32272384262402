import { campaignService } from '@services'
import { Campaign } from '@utils'
import {
  HTTP_CODE_OK,
  APP_LANG_DEFAULT,
  APP_LANG_DEFAULT_FONT,
  APP_LANG_VN_FONT,
  FLOW_TYPE_REDIRECT,
  CAMPAIGN_SERIAL_TYPE,
  WIN_FLAG,
  LOSE_FLAG,
  HTTP_CODE_RATE_LIMIT_EXCEED
} from '@constants'
import { LANGUAGES } from '@i18n'

export default async function checkCampaign({ to, next, router }) {
  const campaignUrl = String(to.params.campaignUrl)
  const campaignUrlName = String(to.params.campaignUrlName)

  try {
    const campaignData = await campaignService.index(campaignUrlName, campaignUrl, {
      include: 'campaign_notes'
    });

    if (campaignData.status !== HTTP_CODE_OK) {
      return await next({
        name: 'campaign.error.404'
      });
    }

    const data = campaignData.data;

    const userData = {
      campaign_url: data.campaign_url,
      campaign_url_name: data.campaign_url_name,
      campaign_infos: data.campaign_infos,
      campaign_notes: data.campaign_notes,
      campaign_settings: data.campaign_settings,
      campaign_country: data.campaign_country
    }
    if (
      JSON.stringify(userData) !==
      JSON.stringify(Campaign.getCampaign())
    ) {
      Campaign.clearCampaign()
      Campaign.clearCampaignLanguage()
      Campaign.setCampaign(data)
    }

    const languageId = data.campaign_country.language
    const fontType = languageId === APP_LANG_DEFAULT ? APP_LANG_DEFAULT_FONT : APP_LANG_VN_FONT
    Campaign.setLanguage(languageId, fontType)
    router.app._i18n.locale = LANGUAGES[languageId]

    // Check campagin flow is redirect
    if (data.campaign_infos.campaign_flow === FLOW_TYPE_REDIRECT) {
      // Check campaign type is serial
      if (data.campaign_infos.campaign_type !== CAMPAIGN_SERIAL_TYPE) {
        return await next({
          name: 'campaign.error.404'
        });
      }
      
      // Check url has serial code
      const regex = /\/[0-9a-zA-Z_-]+\/\w+\/auth$/;
      const serialCode = to.query.code
      if (!serialCode || serialCode === String(0) || !regex.test(window.location.pathname)) {
        return await next({
          name: 'campaign.error.404'
        });
      }

      // joinCampaign
      const lotteryData = await campaignService.lotteryPlayRedirect({
        campaign_url_name: campaignUrlName,
        campaign_url: campaignUrl,
        serial_code: serialCode,
      });

      if (lotteryData.status === HTTP_CODE_OK) {
        const lotteryUuid = lotteryData.data?.lottery_url

        if (lotteryData.data?.win_flag === WIN_FLAG) {
          if (lotteryData.data?.campaign_gift?.gift_url) {
            window.location.href = lotteryData.data.campaign_gift.gift_url
          } else {
            return await next({
              name: 'campaign.error'
            });
          }
        } else if (lotteryData.data?.win_flag === LOSE_FLAG) {
          // Redirect to lost lottery game
          Campaign.clearLotteryCampaign(lotteryUuid)
          Campaign.setLotteryCampaign(lotteryUuid, lotteryData.data)

          return await next({
            name: 'lottery.game',
            params: { campaignUrlName, lotteryUuid }
          });
        } else {
          return await next({
            name: 'campaign.error'
          });
        }
      } else {
        return await next({
          name: 'campaign.error',
          params: {
            IpBlock: lotteryData.data?.status === HTTP_CODE_RATE_LIMIT_EXCEED
          }
        });
      }
    } else { // Campaign flow is Blank | Survey
      return await next();
    }

  } catch (error) {
    return await next({
      name: 'campaign.error'
    });
  }
}
