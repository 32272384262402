import Vue from 'vue'
import VueI18n from 'vue-i18n'
import vnMessage from './vn'
import enMessage from './en'
import { APP_LANG_DEFAULT, APP_LANG_VN } from '@constants'

Vue.use(VueI18n)

export const LANGUAGES = {
  [APP_LANG_DEFAULT]: 'en',
  [APP_LANG_VN]: 'vn'
}

const messages = {
  vn: vnMessage,
  en: enMessage
}

const i18n = new VueI18n({
  locale: LANGUAGES[APP_LANG_DEFAULT],
  messages,
  fallbackLocale: LANGUAGES[APP_LANG_DEFAULT],
  silentTranslationWarn: true
})

export default i18n
