import axios from 'axios'
import { Campaign } from '@utils'
import { HTTP_CODE_NOT_FOUND, HTTP_CODE_BAD_REQUEST } from '@constants'

axios.defaults.baseURL = process.env.VUE_APP_API_DOMAIN

export default class BaseService {
  async get(uri, params = {}, header = {}) {
    try {
      return await axios.get(uri, {
        params: params,
        headers: {
          ...this._defaultHeader(),
          ...header
        }
      })
    } catch (e) {
      return this._errorMsg(e)
    }
  }

  async post(uri, params = {}, header = {}) {
    try {
      return await axios.post(
        uri,
        params,
        {
          headers: {
            ...this._defaultHeader(),
            ...header
          }
        }
      )
    } catch (e) {
      return this._errorMsg(e)
    }
  }

  async put(uri, params = {}, header = {}) {
    try {
      return await axios.put(uri, {
        params: params,
        headers: {
          ...this._defaultHeader(),
          ...header
        }
      })
    } catch (e) {
      return this._errorMsg(e)
    }
  }

  async patch(uri, params = {}, header = {}) {
    try {
      return await axios.patch(uri, {
        params: params,
        headers: {
          ...this._defaultHeader(),
          ...header
        }
      })
    } catch (e) {
      return this._errorMsg(e)
    }
  }

  async show(uri, header = {}) {
    try {
      return await axios.get(uri, {
        headers: {
          ...this._defaultHeader(),
          ...header
        }
      })
    } catch (e) {
      return this._errorMsg(e)
    }
  }

  async delete(uri, header = {}) {
    try {
      return await axios.delete(uri, {
        headers: {
          ...this._defaultHeader(),
          ...header
        }
      })
    } catch (e) {
      return this._errorMsg(e)
    }
  }

  _errorMsg(e) {
    if (e.response === undefined) {
      e.status = 0
      e.statusText = e.message
      return { data: e }
    }

    // Handle error status response will redirect to error page
    switch (e.response.status) {
      case HTTP_CODE_NOT_FOUND:
        window.location.href = '/error-404'
        break
      case HTTP_CODE_BAD_REQUEST:
        break
      default:
        window.location.href = '/error'
        break
    }

    // Response format if was another status error
    return { data: e.response }
  }

  _defaultHeader() {
    return {
      'gift-campaign-url': Campaign.getCampaignUrl(),
      'gift-campaign-url-name': Campaign.getCampaignUrlName()
    }
  }
}
